.mainbox{
    background-color: rgba(0, 0, 0, 0.6);
    height: calc(100svh - 65px);
}

.downloadbtn{
    width: 200px;
    height: 50px;
    margin: 30svh 0 0 calc(50svw - 100px);
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: black;
    background-color: rgb(171, 58, 80);
    font-size: larger;
    cursor:pointer
}
.downloadbtn:hover{
    background-color: rgb(255, 86, 120);
}

.load{
    cursor: wait;
}

.load:hover{
    cursor: wait;
}