.mainbox{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 30svw;
    min-width: 300px;
    padding: 20px;

    z-index: 5;
    background-color: transparent;
    pointer-events: none;
    color: transparent;
    border-radius: 20px;
    user-select: none;
    transition: .3s;
}

.textbox{
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 10px;
    z-index: 1;
}

.btn_cancel{
    position: relative;
    width: 200px;
    min-height: 40px;
    background-color: transparent;
    /* border: 2px solid black; */
    border: none;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    color: transparent;
    z-index: 1;
    margin: 15px;
    pointer-events: none;
    user-select: none;
    transition: .3s;
}

.btn_cancel:hover{
    background-color:rgba(171, 58, 80, 0.8);
}


.showimpressum{
    background-color: rgb(32, 32, 38);
    color: white;
}

.showbtn{
    background: rgb(206, 206, 206);
    color: black;
}

.showimpressum, .showbtn{
    pointer-events: all;
    user-select:auto;
}