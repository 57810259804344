.mainbox{
    position: absolute;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100svw; 
    height: 100svh;
    background-color: rgba(0, 0, 0, 0);
    transition: .2s;
    z-index: 1000;
    pointer-events: none;
}

.imgbox{
    height: 10%;
    width: 10%;
    min-height: 120px;
    min-width: 120px;
    object-fit: contain;
    animation: none;
    pointer-events: none;
    transition: .2s;
    opacity: 0;
}

@keyframes loadingspin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.load{
    opacity: 1;
    animation: loadingspin infinite 2.5s linear;
}

.stopload{
    opacity:0;
    transform: rotate(360deg);
}

.loadScreen{
    background-color: rgba(0, 0, 0, .9);
    pointer-events: all;
}