.mainbox{
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100svw;
    height: 96svh;
    z-index: 3;
    background-color: transparent;
    pointer-events: none;
    backdrop-filter: blur(0px);
    transition: 0.3s;
}

.showbackground{
    backdrop-filter: blur(2px);
    pointer-events: all;
}

.subbox{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 30svw;
    min-width: 300px;
    padding: 20px;

    z-index: 2;
    background-color: transparent;
    pointer-events: none;
    color: transparent;
    border-radius: 20px;
    user-select: none;
    transition: .3s;
}

.textbox{
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 10px;
    z-index: 1;
    padding: 10px;
}

.btnbox{
    display: flex;
    background-color: transparent;
}

.btn{
    position: relative;
    width: 200px;
    min-height: 40px;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    color: transparent;
    z-index: 1;
    margin: 15px;
    pointer-events: none;
    user-select: none;
    transition: .3s;
}

.btn:hover{
    background-color:rgba(171, 58, 80, 1);
}


.showcookies{
    background-color: rgb(32, 32, 38);
    color: white;
}

.showbtn{
    background: rgb(206, 206, 206);
    padding: 5px;
    color: black;
}

.showcookies, .showbtn{
    pointer-events: all;
    user-select: auto;
}

.cookietext{
    text-align: justify;
}