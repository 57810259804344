.editbar{
    position: relative;
    display: flex;
    justify-content:left;
    align-items: center;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.8);
    border-style: solid;
    border-color: rgba(171, 58, 80, 0.8);
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 5px;
    z-index: 2;
}

.navproject {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 1s;
}

.homebtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 60px;
    width: 180px;
    border: none;
    left: 1svw;
    margin-right: 2svw;
}

.editbtn {
    padding: 5px;
    padding-left: 28px;
    padding-right: 28px;
    background: rgba(171, 58, 80, 0.3);
    margin-left: -15px;
    height: 60px;
    width: 240px;
    border: none;
    color:rgb(222, 222, 222);
    font-size: 1.3rem;
    clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%, 0% 0%);
}

.editbtn:hover{
    background: rgba(171, 58, 80, 0.6);
}

.editbtn.active{
    background: rgba(171, 58, 80, 0.8);
}

.savebtn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    padding-top: 15px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -15px;
    background-color: rgba(157, 157, 157, 0.3);;
    border: none;
    height: 60px;
    width: 110px;

    color: rgba(31, 209, 0, 0.8);
    font-size: 30px;
    transition: 1s;
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 20% 50%, 0% 0%);
}

.savebtn:hover{
    /* background-color: rgb(38, 255, 0); */
    color: rgb(38, 255, 0)
}

@keyframes rotatesaved{
    /* 0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg);} */
    0% {transform: rotate(0deg);}
    25% {transform: rotate(-30deg);}
    75% {transform: rotate(30deg);}
    100% {transform: rotate(0deg);}
}

.rotatesave{
    animation: rotatesaved 10s infinite;
}

.unsaved{
    transition: 1s;
    color: rgb(208, 0, 0);
    background-color: rgba(44, 0, 0, 0.6);
    /* background-color: rgb(208, 0, 0); */
}

.unsaved:hover{
    color: rgb(255, 0, 0);
    /* background-color: rgb(255, 0, 0); */
}

button:hover{
    cursor: pointer;
}

.Bar{
    position: absolute;
    top:0;
    visibility: hidden;

    padding: 10px;
    background: none;
    border: none;

    color:rgb(222, 222, 222);
    font-size: 1.5rem;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100svw;
    height: 60px;
}

.selectedButton{
    background: rgba(171, 58, 80, 0.8);
}

.selectedButton:hover{
    background: rgba(171, 58, 80, 0.8);
}

@media only screen and (max-width: 1420px){
    .navproject{
        transform: translateY(-120svh);
        transition: 0s;
    }

    .editbar{
        display: flex;
        justify-content:center;
        align-items: center;
        height: 60px;
        background-color: transparent;
    }

    .navproject {
        position:absolute;
        top:0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100svh;
        width: 100svw;
        background-color: rgba(0, 0, 0, 0.9);
        backdrop-filter: blur(2px);

    }

    .editbtn, .savebtn{
        margin-left: 0px;
        width:240px;
        clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0, 50% 10%);
    }

    .navprojectfirsttrigger {
        transition: 0.5s;
    }

    .Bar{
        visibility: visible;
    }

    .Barclicked{
        visibility: hidden;
    }

    .responsive_nav{
        bottom: auto;
        transform: translateY(0);
    }

    .homebtn{
        position: relative;
    }
}