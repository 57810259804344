.mainbox{
    display: flex;
    flex-direction: column;
    width:100svw;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
    height: calc(100svh - 85px);
    overflow-y: scroll;
    scrollbar-width: thin;

    padding: 10px 0 10px 0;
}

.headerelementbox, .elementbox{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95svw;
    height: 80px;
    border-radius: 15px;
    border-width: 3px;
    border-style: solid;
    margin: 2px;
}
.headerelementbox{
    background-color: rgba(0, 0, 0, 0.9);
    border-color: rgb(171, 58, 80, 0.9);
    margin-top: 5px;
}

.headertext{
    display:flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    top: 50%;
    color: white;

    font-size: 1.5rem;
    width: 95svw;
    height: 60px;
}

.elementbox{
    background-color: rgba(0, 0, 0, 0.8);
    border-color: rgba(0, 0, 0, 0.9);
}

.elementbox:hover{
    background-color: rgba(0, 0, 0, 0.9);
}

.textfield{
    display:flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    top: 50%;
    color: white;

    font-size: 1.1rem;
    width: 70svw;
    height: 60px;
}

.textfield_number{
    margin-left: 1svw;
}

.decision{
    display: flex;
    align-items:center;
    justify-content: center;
    width: 25svw;
    height: 60px;
}

.decisionbtntrue, .decisionbtnfalse{
    width: 10svw;
    height: 50px;
    margin: 1svw;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: black;
}

.decisionbtntrue{
    background-color: rgb(171, 58, 80);

}

.decisionbtnfalse{
    background-color: rgb(255, 255, 255);
}

.decisionbtnfalse:hover{
    background-color: rgb(173, 105, 118);
}

@media only screen and (max-width: 510px){
    .textfield{
        font-size: 0.8rem;
    }
}