.mainbox{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100svh - 65px);
    width:100svw;
    background-color: rgba(0, 0, 0, 0.6);
    overflow-y: scroll;
    scrollbar-width: thin;
}

.titlebox{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90svw;
    min-height: 60px;
    background-color:  rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 2rem;

    margin-top: 1svh;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
}


.summarybox{
    display: flex;
    align-items: center;
    width: 88svw;
    color: white;
    border-style: solid;
    border-top: none;
    border-width: 2px;
    background-color: rgba(0, 0, 0, 0.6);
}

.summarytextarea{
    min-width: calc(85svw - 1svw - 10px - 60px);
    max-width: calc(85svw - 1svw - 10px - 60px);
    font-size: 1.1rem;
    background-color:  transparent;
    color: white;
    border: none;
    outline: none;
    margin-left: 0.5svw;
    padding: 5px;
    resize:vertical;
}

.summarytextarea:hover {
    background: linear-gradient(90deg, transparent 0%, rgb(171, 58, 80, 0.5) 50%, transparent 100%);
}

.summarytextarea:focus {
    background: linear-gradient(90deg, transparent 0%, rgb(171, 58, 80, 0.5) 50%, transparent 100%);
    background-size: 100% 100%;
  }

.summarybox p {
    width: 15px;
    padding: 5px;
    font-size: 1.1rem;
}

.summarydelete, .summaryadd{
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    width: 40px;
    height: 30px;
    border-radius: 15px;
    border-width: 3px;
    border-style: solid;
    font-weight: bold;
    border-color: rgb(171, 58, 80, 0.9);
    top: 10px;
}


.summarydelete{
    height: 25px;
    color: rgb(171, 58, 80, 0.9);
}

.summaryadd{
    position: absolute;
    right: 15px;
    top: 15px;
}

.summaryadd:hover, .summarydelete:hover{
    border-color: rgb(255, 86, 120);
}

@media only screen and (max-width: 430px){
    .title{
        justify-content: left;
        align-items: left;
        font-size: 1.7rem;
    }

    .title p{
        margin-left: 20px;
    }
}
/* .summarydelete:hover, .summaryadd:hover{
    border-color: rgb(255, 86, 120);
} */