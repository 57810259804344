.projectlist{
    position: relative;
    display: grid;
    width:100svw;
    grid-template-columns: repeat(auto-fill, 220px);
    grid-template-rows: 220px;
    justify-content: center;
    background-color: transparent;
}

.buttonbox{
    padding: 10px;
}

.projectbtn{
    width: 200px;
    height: 200px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    border-color:rgb(200, 200, 200);
    color:rgb(222, 222, 222);
    font-size: 1.5rem;
    transition: 0.5s;
}

.projectbtn:hover{
    border-color: rgb(256, 256, 256);
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.8);
    font-size:1.7rem;
    transition: 0.5s;
}

img{
    height: 150px;
    width: 180px;
    object-fit: contain;
    pointer-events: none;
}

/* Eckbuttons */
.X{
    position: absolute;
    margin: 0px 0 0 165px;
    border: none;
    color: white;
    background-color: transparent;
    font-size: 40px;
    transition: 0.3s;
    font-family: sans-serif;
}
.X:hover{
    color:rgb(255, 77, 77);
    margin: 0px 0 0 165px;
    font-size: 40px;
}

.changeNamebtn{
    position: absolute;
    margin: 13px 0px 0px 15px;
    border: none;
    color: white;
    background-color: transparent;
    font-size: 25px;
    transition: 0.3s;
    font-family: sans-serif;
}

.changeNamebtn:hover{
    color:rgb(255, 77, 77);
}

.pencil{
    pointer-events: none;
}

.Addbtn{
    position: relative;
    padding: 10px;
}

.Addbtn img{
    position:absolute;
    bottom: 60px;
    left: 20px;
}

.add_input{
    position: absolute;
    bottom: 43px;
    left: 28px;
    background-color: rgba(98, 98, 98, 0.6);
    border-radius: 5px;
    border-color: rgb(58, 58, 58);
    outline: none;
    transition: 0.3s;
    font-size: 1.5rem;
    width: 160px;
    max-width: 200px;
    color: white;
    padding: 1px;
    text-align: center;
}

.add_input:focus{
    background-color: rgba(0, 0, 0, 0.6);
    border-color: rgb(171, 58, 80);

}

.add_input:hover{
    border-color: rgb(171, 58, 80);
}

.projectbtn2{
    width: 200px;
    height: 200px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    border-color:rgb(169, 0, 0);
    color:rgb(222, 222, 222);
    font-size: 1.5rem;
    transition: 0.5s;
}

.projectbtn2:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.8);
    font-size:1.5rem;
    transition: 0.5s;
}

.addable{
    border-color: rgb(0, 231, 0);
}

.deletebracket{
    position: sticky;
    z-index: 2;
    top: 0;
    background-color: aqua;
}

.deletebox{
    position: absolute;
    display: flex;
    width: 100svw;
    height: 100svh;
    
    justify-content: center;
    align-items: center;

    transform: translateY(-100svh);
}

/* firstload */
.responsive_deleterequest{
    transform: translateY(0svh);
}

.responsive_transition {
    transition: .6s;
}

.blurbox {
    position: absolute;
    height: 120svh;
    width: 100svw;
    z-index: 0;
    pointer-events: none;
    transition: 0.4s;
}
.responsive_blur{
    background-color: rgba(0, 0, 0, 0.8);
}

.runningtext{
    overflow: hidden;
    padding: 0 5px 0 5px;
}

.projectbtn:hover .runningtext{
    animation: moveleft 8000ms ease-in-out infinite;
}

@keyframes moveleft {
    0% {
        text-indent: 0px;
    }
    10% {
        text-indent: 0px;
    }
    40% {
        text-indent: -240px;
    }
    60% {
        text-indent: -240px;
    }
    90% {
        text-indent: 0px;
    }
    100% {
        text-indent: 0px;
    }
}