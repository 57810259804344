.mainbox{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100svw;
    height: 100svh;
    pointer-events: none;
    transition:0.5s;
    z-index: 4;
}

.mainbox_blur{
    backdrop-filter: blur(2px);
    pointer-events: all;
}

.subbox{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(32, 32, 38);
    color: white;
    transform: translateY(-100svh);
    height: 500px;
    width: 500px;
    border-radius: 30px;
    z-index: 5;
    transition:0.5s;
}
.showchangepw{
    transform:translateY(0)
}

.title{
    margin-top: 15px;
    max-width: 60svw;
    padding: 15px;
    font-size: 2.5rem;
    transition: 0.3s;
}

.inputbox{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 250px;
}

.input{
    all: unset;
    border-radius: 3px;
    border-style: none;
    height: 20px;
    width: 90%;
    padding: 5px;
    background-color: white;
    color: black;
    margin: 5px 0 0 0;
}

.passwordbox{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 300px;
}

.subtitle{
    font-size: 1.3rem;
}

.confirm, .decline{
    all: unset;
    background-color: rgb(206, 206, 206);
    color: rgb(0, 0, 0);
    margin: 15px 15px 30px 15px;
    padding: 15px;
    border-radius: 15px;
    font-weight: bold;
    transition: 0.3s;

    border-color: black;
    border-style: none;
}

.confirm:hover, .decline:hover{
    background-color: rgb(171, 58, 80);
}

@media only screen and (max-width: 550px){
    .subbox{
        height: 80svh;
        width: 80svw;
    }

    .title{
        font-size: 1.7rem;
    }

    .passwordbox{
        width: 78svw;
    }
}