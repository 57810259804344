body {
    background-image: url('../../background.jpg');
    background-repeat: no-repeat;
    background-attachment:fixed;
    background-position: top center;
    background-size: cover;
    background-color: rgb(0, 0, 0);
}

header {


    position: relative;
    display: flex;
    justify-content:space-between;
    align-items: center;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.8);
    border-style: solid;
    border-color: rgba(171, 58, 80, 0.8);
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 5px;
    z-index: 2;
}

header .navbtn{
    position: absolute;
    top: 10px;
    right: 50px;
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: white;
    visibility: hidden;
    opacity: 0;
    font-size: 2rem;
}

header .navclosebtn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: white;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

header .logoarea {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 1svw;
    background-color: transparent;
    height: 60px;
    width: 180px;
}

header .logoarea img {
    height: 90px;
    width: 180px;
    object-fit: contain;
}

.homebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 60px;
    width: 180px;
    border: none;
}

.navbutton{
    margin: 0 2rem;
    color: rgb(200, 200, 200);
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
}

.navbutton:hover{
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.currentpage{
    /* margin: 0 2rem; */
    color: rgb(200, 200, 200);
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
}

@media only screen and (max-width: 812px){
    body{
        background-size: cover;
    }
    header .navbtn{
        visibility: visible;
        opacity: 1;
        transition: 0.5s;
    }

    header .nav{
        position: fixed;
        top:0;
        left:0;
        height: 100svh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: rgba(0, 0, 0, 0.8);
        transform: translateY(-100svh);
    }

    header .secondnav{
        position: fixed;
        top:0;
        left:0;
        height: 100svh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        transition: 0.5s;
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(15px);
        transform: translateY(-100svh)
    }

    header .responsive_nav{
        transform: none;
        transition: 0.5s;
    }

    header .responsive_btn{
        visibility: hidden;
        opacity: 0;
    }

    header .navclosebtn{
        position:absolute;
        top: 10px;
        right: 50px;
        visibility: visible;
        opacity: 1;
    }

    .navbutton{
        font-size: 1.5rem;
    }

    .currentpage{
        visibility: hidden;
    }
}