.mainbox{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    height: calc(40svh + 36px + 5svh);
    background-color: rgba(255, 255,255, 0.9);
    border-radius: 15px;
    padding-top: 1svw;
}
.header{
    font-size: large;
    font-weight: bold;
    margin-bottom: 10px;
    height: 20px;
}   

.btnbox{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btn, .btn1, .btn2, .btn3, .btn4{
    height: 5svh;
    width: 80px;
    border-color: black;
    border-style:solid;
    font-weight: bold;
    margin: 1px 1px 1px 1px;
}
.btn1{background-color: rgb(255, 0, 0);}
.btn2{background-color: rgb(255, 111, 0);}
.btn3{background-color: rgb(255, 247, 0);}
.btn4{background-color: rgb(51, 255, 0);}
.widebtn{
    height: 5svh;
    width: calc(320px + 8px);
    background-color: rgb(0, 174, 255);
    border-color: black;
    border-style:solid;
    font-weight: bold;
}
.btn1:hover, .btn2:hover, .btn3:hover, .btn4:hover,.widebtn:hover{background-color: rgb(255, 255, 255);}


.title1, .title2, .title3, .title4, .title5, .title6, .title7, .title8, .title9, .title10, .title11, .title12, .title13, .title14, .title15, .title16, .emptybox1, .emptybox2, .emptybox3, .emptybox4{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5svh;
    width: 80px;
    border-color: black;
    border-style:solid;
    font-weight: bold;
    border-width: 0.1svh;
    margin: 1px 1px 1px 1px;
}
.emptybox1, .emptybox3{
    justify-content: flex-start;
}
.title11, .title12, .title13, .title14, .title15, .title16, .emptybox2, .emptybox4{
    width: 120px;
}
.title1:hover, .title2:hover, .title3:hover, .title4:hover, .title5:hover, .title6:hover, .title7:hover, .title8:hover, .title9:hover, .title10:hover, .title11:hover, .title12:hover, .title13:hover, .title14:hover, .title15:hover, .title16:hover, .emptybox1:hover, .emptybox2:hover, .emptybox3:hover, .emptybox4:hover{
    cursor: default;
}

.emptybox1, .emptybox2, .emptybox3, .emptybox4{
    display: flex;
    align-items: center;
    height: 5svh;
    background-color: transparent;
    border: none
}

.title1, .title5, .title11{
    background-color: rgb(255, 0, 0, 0.9);
}
.title2, .title6, .title12{
    background-color: rgb(255, 111, 0, 0.9);
}
.title3, .title7, .title13{
    background-color: rgb(255, 247, 0, 0.9);
}
.title4, .title9, .title15{
    background-color: rgb(51, 255, 0, 0.9);
}
.title8, .title14{
    background-color: rgb(25, 126, 0, 0.9);
}
.title10, .title16{
    background-color: rgb(0, 174, 255, 0.9);
}

@media only screen and (max-width: 950px){
    .title11, .title12, .title13, .title14, .title15, .title16, .emptybox1, .emptybox3{
        width: 0px;
        visibility: hidden;
    }
    .title5, .title6, .title7, .title8, .title9, .title10, .emptybox2, .emptybox4{
        width: 25px;
    }
    .emptybox1, .emptybox2, .emptybox3, .emptybox4{
        visibility: hidden;
    }
    .mainbox{
        width: 420px;
        height: calc(40svh + 36px + 5svh);
    }
}