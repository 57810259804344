.all{
    overflow: hidden;
}

.Mainbox{
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    min-height: calc(100svh - 65px);
}

.Verdictbox{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 62svw; */
    min-width: 62svw;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    height: calc(100svh - 65px);
}

.judgecontent{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.judgetitel, .judgedangers, .judgemeasures, .judgemeasures2, .judgedangerselement{
    position:relative;
    display: flex;
    align-items: center;
    color:white;
    font-size: 1.5rem;
    width: 57svw;
    border-radius: 10px;
    border-width: 3px;
    border-style: solid;
    margin-top: 10px;
    height: 30px;

    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    border-color: rgba(202, 202, 202, 0.9);
}

.judgetitel{
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    min-height: 95px;
    margin: 15px 0 0 0; 
}

.handyjudgetitel{
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    height: 0px;
    visibility:hidden;
    position:relative;
    display: flex;
    color:white;
    font-size: 1.5rem;
    width: 57svw;
    border-radius: 15px;
    border-width: 3px;
    /* border-style: solid;
    margin: 15px 0 0 0;
    padding: 1svw; */
    padding: 1svw;
    background-color: rgba(0, 0, 0, 0.8);
    border-color: rgb(171, 58, 80, 0.9);
}

.handyjudgetitel:hover{
    border-color: rgb(255, 86, 120);
}

.judgedangerselement{
    display: flex;
    justify-content:flex-start;
    width: 55svw;
    border-radius: 0px;
    margin: 0 0 0 0svw;
    border-top: none;
}

.riskjudgementbracket{
    position: sticky;
    z-index: 2;
    top: 0;
}

.riskjudgementselector{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 640px;
    height: 450px;
    top: calc(50svh - 225px);
    left: calc(50svw - 320px);
    transform: translateY(-100svh);
}


.judgementselected{
    transform: translateY(0svh)
}

.smoothjudgement{
    transition: 0.8s;
}

.judgedangerinput{
    width: 37svw;
    margin-left: 1svw;
    outline: none;
    background-color: rgba(255, 255, 255, 0.7);
    transition: background-color .4s;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    font-size: 1.5rem;
    height: 30px;
    padding: 3px;
}
.judgedangerinput:focus{
    background-color: rgba(255, 255, 255, 0.9);
}

.founddangers{
    width: 380px;
}

.dangerrisk{
    position: absolute;
    /* width: 190px; */
    justify-content: center;
    align-items: center;
    margin-left: 4svw;
    font-size: 25px;
    left: 35.5svw;
}

.addbtn, .riskbtn, .deletebtn, .presetbtn{
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    width: 40px;
    height: 30px;
    border-radius: 15px;
    border-width: 3px;
    border-style: solid;
    font-weight: bold;
    border-color: rgb(171, 58, 80, 0.9);
    top: 10px;
}

.addbtn{
    position: absolute;
    left: 54svw;
    width: 40px;
}

.riskbtn{
    position: absolute;
    left: 43svw;
    width: 60px;
}

.deletebtn{
    position: absolute;
    left: 52svw;
    color: rgb(171, 58, 80, 0.9);
}

.presetbtn{
    height:25px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.presetbtn:hover{
    cursor: pointer;
    border-color: rgb(255, 86, 120);
}

.addbtn:hover, .deletebtn:hover{
    border-color: rgb(255, 86, 120);
}

.riskbtn:hover{
    border-color: rgb(255, 86, 120);
}


.VerdictCheckbox{
    display: flex;
    flex-direction: column;
    /* width: 38svw; */
    min-width: 3svw;
    height: calc(100svh - 65px);
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
}

.SetAllVerdictsBox{
    width: calc(38svw - 10px);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.OpenAllVerdicts{
    color: white;
    background-color: rgba(0, 130, 0, 0.8);

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-color: rgba(202, 202, 202, 0.9);
    border-style: solid;

    padding: 5px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    transition: 0.3s;
}
.OpenAllVerdicts:hover{
    background-color: rgba(0, 170, 8, 0.8);
}

.CloseAllVerdicts{
    color: white;
    background-color: rgba(130, 0, 0, 0.8);

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: rgba(202, 202, 202, 0.9);
    border-style: solid;
    border-left: none;

    padding: 5px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    transition: 0.3s;
}
.CloseAllVerdicts:hover{
    background-color: rgba(170, 0, 8, 0.8);
}

.Checkboxbtntrue, .Checkboxbtnfalse{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-style: solid;
    border-color: rgba(202, 202, 202, 0.9);
    border-right: none;
    border-top: none;
    border-bottom: none;
    border-left-width: 1px;

    
    /* border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
    padding: 5px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    transition: 0.3s;
}

.Checkboxbtntitle{
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-style: solid;
    border-color: rgba(202, 202, 202, 0.9);
    border-width: 3px;
    border-right: none;
    border-top: none;
    border-bottom-width: 1px;
    border-left-width: 1px;
    /* border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
    padding: 5px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    transition: 0.3s;
}

.Checkboxbtntrue{
    background-color: rgba(10, 10, 10, 0.8);
}

.Checkboxbtntrue:hover{
    background-color: rgba(35, 35, 35, 0.8);
}

.Checkboxbtnfalse{
    background-color: rgba(98, 25, 39, 0.8);
}

.Checkboxbtnfalse:hover{
    background-color: rgba(134, 43, 61, 0.9);
}

.Number{
    width: 3svw;
    pointer-events: none;
    padding: 5px;
}

/* .TitleNumber{
    width: 3svw;
    pointer-events: none;
    padding: 5px;

} */

.title{
    width: calc(32svw - 20px);
    text-align: start;
    pointer-events: none;
}

.ChecklistBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    transform: translateX(calc(35svw + 1px));
    transition: 0.5s;
    /* height: 10svh; */
    z-index: 0;
}

.ChecklistBoxButton{
    height: 100%;
    width: 3svw;
    min-width: 25px;

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-color: rgba(202, 202, 202, 0.9);
    border-style: solid;
    border-right: none;
    background-color: rgba(0, 0, 0, 0.8);
    transition: 0.3s;
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
}

.ChecklistBoxButton:hover{
    background-color: rgba(134, 43, 61, 0.9);
}

.ChecklistRightBox{
    border-color: rgba(202, 202, 202, 0.9);
    border-width: 2px;
    border-style: solid;
    border-left: none;
    border-right: none;
}

.SelectedCheckboxArea{
    transform: translateX(0);
    height: auto;
    /* z-index: 1; */
}

.Emptydiv{
    width: 0;
    height: 0;
}

.btn_back{
    height: 0px;
    border-color: rgba(202, 202, 202, 0.9);
    border-style: solid;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 1.2rem;
    font-weight: bold;
    color: white;

    width: 100%;
    border: none;
    border-bottom: 5px;
    border-radius: 0;
    visibility: hidden;
}

@media only screen and (max-width: 1290px){
    .dangerrisk{
        font-size: 1.95svw;
    }
}
@media only screen and (max-width: 1000px){
    .Verdictbox{
        display: flex;
        align-items: center;
        width: 100svw;
    }
    .VerdictCheckbox{
        position: absolute;
        transform: translateY(-100svh);
        width: 100svw;
        background-color: rgba(0, 0, 0, .8);
    }
    .VerdictCheckboxtoggle1{
        transition: 1s;
    }
    .VerdictCheckboxtoggle2{
        transform: translateY(0);
    }
    .title{
        width: calc(100svw - 50px);
        text-align: start;
        pointer-events: none;
    }
    .Number{
        width: 50px;
    }
    .Checkboxbtntrue, .Checkboxbtnfalse, .Checkboxbtntitle{
        display:flex;
        flex-direction: row;
    }
    .riskjudgementselector{
        width: 420px;
        height: 330px;
        top: calc(50svh - 165px);
        left: calc(50svw - 210px);
    }
    .judgetitel, .judgedangers, .judgemeasures, .judgemeasures2, .judgedangerselement{
        border-radius: 0px;
        font-size: 1.3rem;
        width: 90svw
    }
    .judgedangerselement{
        width: 88svw;
        margin: 0 0 0 0;
    }
    .handyjudgetitel{
        min-height: 200px;
        visibility: visible;
        border-style: solid;
        margin: 15px 0 0 0;
        width: 90svw;
    }

    .judgetitel{
        min-height: 0px;
        height: 0px;
        visibility: hidden;
        border-style: none;
        margin: 0 0 0 0;
        padding: 0;
    }

    .addbtn{
        position: absolute;
        left: 82svw;
        height: 30px;
        width: 40px;
    }
    
    .riskbtn{
        position: absolute;
        height: 30px;
        left: 63svw;
        width: 60px;
    }
    
    .deletebtn{
        position: absolute;
        height: 30px;
        left: 80svw;
        color: rgb(171, 58, 80, 0.9);
    }

    .judgedangerinput{
        width: 50svw;
        margin-left: 1svw;
    }

    .dangerrisk{
        font-size: 2.6svw;
        left: 57svw;

    }

    .ChecklistBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateX(0);
    }

    .ChecklistBoxButton{
        visibility: hidden;
        width: 100%;
        border: none;
        border-radius: 0;
        height: 0;
    }

    .SelectedCheckboxArea{
        transform: translateX(0);
        height: auto;
        /* z-index: 1; */
    }

    .ChecklistRightBox{
        border-bottom: none;
    }

    .btn_back{
        border-color: aliceblue;
        height: 40px;
        visibility: visible;
    }
    .SetAllVerdictsBox{
        visibility: hidden;
        height: 0;
        padding: 0;
    }
    .OpenAllVerdicts{
        visibility: hidden;
        height: 0;
    }
    .CloseAllVerdicts{
        visibility: hidden;
        height: 0;
    }
}

@media only screen and (max-width: 550px){
    .dangerrisk{
        font-size: 0.6rem;
        top: 35px;
    }
}