section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100svh;
    width: 100svw;
    
    background: url('../../background.jpg')no-repeat;
    background-position: center;
    background-size: cover;
}

img{
    object-fit: contain;
}
.form{
    position: fixed;
    width: 400px;
    height: 450px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.responsive_form{
    transition: 1s;
    transform: translateX(100svw);
}

.logob{
    position: relative;
    margin: 30px 0;
    width: 310px;
    height: 10px;
}

.title{
    font-size: 2em;
    color: rgb(208, 208, 208);
    text-align: center;
}

.input{
    position: relative;
    margin: 30px 0;
    width: 310px;
    border-bottom: 2px solid #fff;
}

.input label{
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color:#fff;
    font-size: 1em;
    pointer-events: none;
    transition: .5s;
}

input:focus ~ label,
input:hover ~ label,
input:valid ~ label{

top: -5px;
}
.input input{
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    padding: 0 35px 0 5px;
    color: #fff;
}

.input ion-icon{
    position:absolute;
    right: 8px;
    color: #fff;
    font-size: 1.2em;
    top: 20px;
}

.forget{
    margin: -15px 0 15px;
    font-size:.9em;
    color: #fff;
    display: flex;
    justify-content: center;
}

.forget label input{
    margin-right: 3px;
}

.forget label a{
    color: #fff;
    text-decoration: none;
}

.forget label a:hover{
    text-decoration: underline;
}
.btn{
    width: 100%;
    height: 40px;
    background:transparent;
    border: 2px solid #fff;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    position: relative;
    color:#fff;
}

.btn:hover, .btn:focus{
    background-color: rgba(171, 58, 80, 0.8);
}

/* .btn::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(171, 58, 80);
    z-index: -1;
    transition: transform 0.5s;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
}

.btn::before{
    transform:scaleX(0);
}

.btn:hover::before{
    transform:scaleX(0.3);
}

.btn:focus::before{
    transform:scaleX(1)
} */

.register{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    font-size: .9em;
    color: white;
    margin: 25px 0 10px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.register p{
    text-decoration: none;
    color:#fff;
    font-weight: 600;
    font-size: .9em;
    margin-right:3px;
}
.register p a:hover{
    text-decoration: underline;
}

.registerbtn{
    font-weight: 600;
    font-size: 1em;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: transparent;
    color: white;
    border-color: transparent;
}

.registerbtn:hover{
    text-decoration: underline;
}

.logo {
    width: 250px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.version{
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px;
    color: rgba(0, 0, 0, .3);
}

.impressum{
    position: relative;
    background-color: transparent;
    color: white;
    font-size: 2svh;
    padding: 5px;

    /* border: 2px solid black; */
    border: none;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    font-weight: 600;
    transition: .3s;
}

.impressum:hover{
    background-color: rgba(0, 0, 0, .4);
}

.Container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.subbox{
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    bottom: 0;
    width: 100svw;
    height: 4svh;
    background-color: rgba(171, 58, 80, 1);
}

@media only screen and (max-width: 430px){
    .form{
        width: 95svw;
    }
}