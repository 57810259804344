.mainbox{
    background-color: rgba(0, 0, 0, 0.6);
}

.subbox{
    height: calc(100svh - 65px);
    overflow-y: scroll;
    scrollbar-width: thin;
    overflow-x: hidden;
}

.general_mainbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.general_info{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100svw;
    padding: 5px;
}

.general_logoimg{
    width: 80svw;
    border-radius: 5px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.8);
}

.info_form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 0 0;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.8);
    width: 80svw;
    height: 40px;
    padding: 5px;
}

.info_form p{
    color: white;
    font-size: 1.1rem;
    padding: 5px;
}

.info_form input{
    padding: 5px;
    width: 400px;
    margin-right: 1svw;
    outline: none;
    border: none;
    border-radius: 4px;
    background-color:  rgb(171, 58, 80, 0.8);
    font-size: 1.1rem;
    color: white;
}

.info_forminput:hover, .info_forminput:focus{
    background-color:  rgb(171, 58, 80, 1);
}

.general_working_equipment{
    display: grid;
    justify-content: center;
    align-items: center;
    width: 50svw;
    min-height: 320px;
    grid-template-columns: 25svw 25svw;
}

.equiptmentinput{
    display: none;
}

.equiptmentinputbox{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

.equiptmentinputbtn{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 24svw;
    height: 140px;

    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    border-width: 2px;
    border-color: rgb(171, 58, 80, 0.8);
}

.equiptmentinputbtn:hover{
    background-color: rgba(0, 0, 0, 0.9);
}

.equiptmentinputimg{
    width: 23svw;
    height: 136px;
    border-radius: 15px;
}

.equiptmentinputp{
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.8); */
    color: white;
    padding: 2px;
    border-radius: 5px;
}

.equiptmentinputdelete{
    position: absolute;
    height: 15px;
    width: 15px;
    right: 20px ;
    top: 15px;
    background-color: rgba(155, 0, 0, 0.9);
    border-radius: 15px;
    border-color: black;
    transition: .3s;
    z-index: 1;
}

.equiptmentinputdelete:hover{
    height: 25px;
    width: 25px;

}

.descriptionbox{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50svh;
    width: 100svw;
}

.description{
    margin: 15px 35px 15px 35px;
    height: 50svh;
    width: 30svw;
    background-color: rgba(0, 0, 0, 0.8);
    border-color: rgb(171, 58, 80, 0.8);
    border-width: 4px;
    color: white;
    padding: 2svw;
    outline: none;
    transition: 0.3s;
    font-size: 0.85svw;
    resize: none;
}

.description:focus{
    background-color: rgba(0, 0, 0, 0.9);
}

@media only screen and (max-width: 950px){
    .general_mainbox{
        flex-direction: column;
    }

    .general_info{
        width: 100svw;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 450px;
    }

    .general_logoimg{
        width:80svw;
    }

    .info_form{
        justify-content:center;
        flex-direction: column;

        width: 80svw;
        height: 80px;
    }

    .info_form input{
        width: 70svw;
    }

    .general_description{
        width: 100svw;
    }

    .general_working_equipment{
        width: 80svw;
        grid-template-columns: 40svw 40svw;
        grid-template-rows: 160px 160px;
    }
    .equiptmentinputbtn{
        width: 38svw;
        height: 130px;
    }
    .equiptmentinputimg{
        width: 36svw;
        height: 126px;
    }
    .equiptmentinputp{
        font-size: 0.9rem;
    }
    .description{
        width: 80svw;
    }
}