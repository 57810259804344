.mainbox{
    position: absolute;
    display: grid;
    grid-template-rows: 10% 80% 10%;

    width: 80svw;
    height: 80svh;
    min-width: 300px;
    padding: 20px;

    z-index: 5;
    background-color: transparent;
    pointer-events: none;
    color: transparent;
    border-radius: 20px;
    user-select: none;
    transition: .3s;
}

.title{
    position: sticky;
    z-index: 2;
    font-size: 3.5rem;
    padding-left: 10px;
}

.textbox{
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    line-height: 150%;
    color: transparent;
    overflow-y: scroll;
    scrollbar-width: none;
}

.subtitle{
    font-size: 2rem;
    padding: 40px 0px 20px 10px;
    text-align: left;
}

.text{
    padding: 0 10px 0 10px;
    text-align: justify;
}

.showdatenschutz .subtitle::before{
    display: flex;
    content: "";
    height: 0.5em;
    width: 40%;
    margin-bottom: 5px;
    border-top: 1px solid rgb(255, 255, 255);
    z-index: -1;
}

.btnbox{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn{
    position: relative;
    width: 200px;
    min-height: 40px;
    background-color: transparent;
    /* border: 2px solid black; */
    border: none;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    color: transparent;
    z-index: 1;
    margin: 15px;
    pointer-events: none;
    user-select: none;
    transition: .3s;
}

.btn:hover{
    background-color:rgba(171, 58, 80, 0.8);
}

.showdatenschutz{
    background-color: rgb(32, 32, 38);
    color: white;
}

.showbtn{
    background: rgb(206, 206, 206);
    color: black;
}

.showtextbox{
    scrollbar-width: thin;
    color: white;
}

.showdatenschutz, .showbtn, .showtextbox{
    pointer-events: all;
    user-select:auto;
}

.link{
    cursor: pointer;
    padding-left: 10px;
    color: transparent;
    width: 200px;
}

.showdatenschutz .link{
    color: rgba(171, 58, 80, 0.8);
}