.deleteconfirm{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba(238, 238, 238, 0.9);
    height: 30svh;
    min-height: 200px;
    width: 500px;
    padding: 2svw;
    max-width: 86svw;

    border-radius: 20px;
    text-align: center;
}

.deleteconfirm p{
    color:grey;
}

.pinput{
    margin: 20px;
    width: 200px;
    border-radius: 1px;
    border-color: black;
    font-size: 1.5rem;
    transition: 0.3s;
    background-color: rgba(238, 238, 238, 0.9);
    padding: 2px;
}

.pinput:focus{
    background-color:  rgba(214, 74, 102, 0.9);
    outline: none;
}

.btn_box{
    display: flex;
    justify-content: space-between;
    background-color:transparent;
    width: 500px;
    max-width: 80svw;
}

.btn_confirm, .btn_cancel{
    width: 100%;
    height: 40px;
    background:transparent;
    border: 2px solid black;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    position: relative;
    color:black;
    z-index: 1;
    margin: 5px;
    /* position:relative;
    font-family: "Gill Sans", sans-serif;
    font-size: 1.5rem;
    width: 250px;
    height: 50px;
    margin: 5px;
    transition: .3s;
    border-radius: 5px;
    background-color: transparent; */
}

.btn_confirm:hover, .btn_cancel:hover{
    background-color: rgb(171, 58, 80);
}
/* .btn_confirm::before, .btn_cancel::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(171, 58, 80);
    z-index: -1;
    transition: transform 0.5s;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
}

.btn_confirm::before, .btn_cancel::before{
    transform:scaleX(0);
}

.btn_confirm:hover::before, .btn_cancel:hover::before{
    transform:scaleX(0.3);
}

.btn_confirm:focus::before, .btn_cancel:focus::before{
    transform:scaleX(1)
} */